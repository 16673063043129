import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Button from '../../../reusecore/src/elements/Button';
import Image from '../../../reusecore/src/elements/Image';
import Container from '../../../common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { envelope } from 'react-icons-kit/fa/envelope';
import { phone } from 'react-icons-kit/fa/phone';

import SectionWrapper, { ContentWrapper, ButtonGroup } from './designedAndBuilt.style';

const DesignedAndBuilt = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        designAndBuilt {
          image {
            publicURL
          }
          title
          description
          description2
          description3
        }
      }
    }
  `);

  const { image, title, description, description2, description3 } = data.appClassicJson.designAndBuilt;

  return (


    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={image.publicURL} alt="Built Logo" width={700} />
          </div>
          <div className="content">
            <Heading content={title} />
            <Text content={description} />
            <Text content={description2}></Text>
            <Text content={description3} />
            <ButtonGroup>
              <Button
                icon={<Icon icon={envelope} />}
                iconPosition="left"
                className="primary"
                title="Email us"
                onClick={() => { window.location.href = 'mailto:hello@vudu.tech' }}
              />
              <Button
                icon={<Icon icon={phone} />}
                iconPosition="left"
                variant="textButton"
                className="secondary"
                title="02921 900 600"
                onClick={() => { window.location.href = 'tel:02921900600' }}
              />
            </ButtonGroup>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default DesignedAndBuilt;
