import React from 'react';
import Fade from 'react-reveal/Fade';
// import { Icon } from 'react-icons-kit';
// import { playCircle } from 'react-icons-kit/fa/playCircle';
// import { infoCircle } from 'react-icons-kit/fa/infoCircle';

import Text from '../../../reusecore/src/elements/Text';
import Image from '../../../reusecore/src/elements/Image';
// import Button from '../../../reusecore/src/elements/Button';
import Heading from '../../../reusecore/src/elements/Heading';
// import Rating from '../../../common/src/components/Rating';
import Container from '../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  // RatingInfo,
  BannerImage,
  // ButtonGroup,
} from './banner.style';

import bannerImg from '../../../common/src/assets/image/appClassic/bannerImg.png';

const Banner = () => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up>
            {/* <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt="Microsoft" />
            </RatingInfo> */}
          </Fade>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Meet Remedi Repeats."
            />
          </Fade>
          <Fade up delay={200}>
            <Heading
              as="h1"
              content="Repeat prescriptions in your customers' hands."
            />
          </Fade>
          <Fade up delay={400}>
            <Text
              content="Remedi is a suite of apps that empowers pharamcies to manage their digital data securely, allowing them to scale massively."
            />
          </Fade>
          <Fade up delay={400}>
            {/* <ButtonGroup>
              <Button className="primary" title="Sign up" onClick={() => {alert('fo')}} />
              <Button
                className="testing"
                variant="textButton"
                icon={<Icon icon={infoCircle} />}
                iconPosition="left"
                title="Find out more"
              />
            </ButtonGroup> */}
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={400}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
