import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../../reusecore/src/elements/Box';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import Heading from '../../../reusecore/src/elements/Heading';
import Container from '../../../common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { infoCircle } from 'react-icons-kit/fa/infoCircle'
import { buildingO } from 'react-icons-kit/fa/buildingO'
import { envelopeO } from 'react-icons-kit/fa/envelopeO'

import FooterArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from './footer.style';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            icon
            title
            description
          }
        }
      }
    }
  `);

  const { logo, menu, widgets } = data.appClassicJson.footer;

  const date = new Date();
  const year = date.getFullYear();

  const getIcon = (iconName) => {
    if (iconName != null) {
      switch (iconName.toLowerCase()) {
        case 'buildingo':
          return buildingO;
        case 'envelopeo':
          return envelopeO;
        default:
          return infoCircle;
      }
    } else {
      return infoCircle;
    }
  };

  return (
    <FooterArea>
      <Container>
        <WidgetArea>
          {widgets.map(item => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Icon size={40} icon={getIcon(item.icon)} />
              <Heading as="h3" content={item.title} />
              <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
            </Box>
          ))}
        </WidgetArea>
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="#home"
            logoSrc={logo.publicURL}
            title="App Classic"
          />
          <Menu>
            {menu.map(item => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>{item.text}</a>
              </MenuItem>
            ))}
          </Menu>
          <CopyrightText>Copyright &copy; {year} Vudu Technologies Limited</CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
