import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { phone } from 'react-icons-kit/fa/phone';

import Fade from 'react-reveal/Fade';
// import Image from '../../../reusecore/src/elements/Image';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Button from '../../../reusecore/src/elements/Button';
import JoinTrailArea, { ContentWrapper, ButtonGroup } from './joinTrail.style';

const JoinTrail = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        joinSlack {
          title
          description
        }
      }
    }
  `);
  const { title, description } = data.appClassicJson.joinSlack;

  return (
    <JoinTrailArea id="trail">
      <ContentWrapper>
        <Fade up delay={100}>
          <Heading content={title} />
        </Fade>
        <Fade up delay={200}>
          <Text content={description} />
        </Fade>
        <Fade up delay={300}>
          <ButtonGroup>
            {/* <Button title="Sign up now" /> */}
            <Button 
              icon={<Icon icon={phone} />} 
              iconPosition="left" 
              title="02921 900 600" 
              variant="textButton"
              className="secondary"
              onClick={() => { window.location.href = 'tel:02921900600' }} 
            />
          </ButtonGroup>
        </Fade>
      </ContentWrapper>
    </JoinTrailArea>
  );
};

export default JoinTrail;
