export const vudu = {
  lightPurple: '#c03cc9',
  darkPurple: '#952e9c',
  grey: '#a1a1a1',
  blue: '#178dd1'
};

const colors = {
  transparent: 'transparent',
  labelColor: '#767676',
  lightBorder: '#f1f4f6',
  inactiveField: '#F7F8FB',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: vudu.lightPurple, //'#3C74FF',
  secondary: vudu.blue, //'#09142E',
  secondaryHover: '#112045',
  yellow: '#FFA740',
  yellowHover: '#F6C416',
  borderColor: '#E8EBF1',
  black: '#000000',
  white: '#ffffff',
  light: '#FAFBFF',
  gray: vudu.grey, //'#E4E4E4',
  primary: vudu.darkPurple, //'#2563FF',
  headingColor: '#0F2137',
  quoteText: '#343D48',
  menu: '#0D233E',
  textColor: 'rgba(52, 61, 72, 0.8)',
  linkColor: vudu.darkPurple, //'#2b9eff',
  shadow: 'rgba(38, 78, 118, 0.1)',
};

export default colors;
