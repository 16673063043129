import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Image from '../../../reusecore/src/elements/Image';
import Container from '../../../common/src/components/UI/Container';
import FeatureBlock from '../../../common/src/components/FeatureBlock';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { FeatureWrapper } from './features.style';

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        features {
          slogan
          title
          description
          description2
          description3
          description4
          description5
          description6
          items {
            id
            color
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);
  const { slogan, title, description, description2, description3, description4, description5, description6,  items } = data.appClassicJson.features;

  const calculateSheetsOfPaperSaved = () => {
    const launchDate = new Date('10/28/2019');
    const today = new Date();
    const timeDifference = Math.abs(launchDate - today);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const approxWeekendDays = Math.floor(daysDifference / 30) * 4;
    const daysLive = daysDifference - approxWeekendDays
    const sheetsPerDay = 295 // the amount of sheets used per day!
    const sheetsOfPaperSaved = daysLive * sheetsPerDay;

    return `${sheetsOfPaperSaved.toLocaleString()} sheets of paper!`
  }



  return (
    <SectionWrapper id="about">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
          </Fade>
        </SectionHeader>
        <Fade up>
          <Text content={description}></Text>
        </Fade>
        <Fade up>
          <Text dangerouslySetInnerHTML={{__html: description2}}></Text>
          <Text dangerouslySetInnerHTML={{__html: description3}}></Text>
          <Text dangerouslySetInnerHTML={{__html: description4}}></Text>
          <Text content={description5} />
          <Heading as="h1" content={calculateSheetsOfPaperSaved()} />
        </Fade>
        <Fade up delay={100}>
          <Text content={description6}></Text>
        </Fade>

        <FeatureWrapper>

          {items.map(item => (
            <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
              <FeatureBlock
                style={{ '--color': `${item.color}` }}
                icon={
                  <Fragment>
                    <Icon className="plus" icon={plus} />
                    <Icon className="circle" icon={mediaRecordOutline} />
                    <Image src={item.icon.publicURL} alt={item.title} />
                    <Icon className="star" icon={starOutline} />
                  </Fragment>
                }
                iconPosition="left"
                title={<Heading as="h3" content={item.title} />}
                description={<Text content={item.description} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Features;
