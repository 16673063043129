import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';

import { lock } from 'react-icons-kit/fa/lock';
import { trophy } from 'react-icons-kit/fa/trophy';
import { cloud } from 'react-icons-kit/fa/cloud';

import { users } from 'react-icons-kit/fa/users';
import { thumbsUp } from 'react-icons-kit/fa/thumbsUp';
import { signIn } from 'react-icons-kit/fa/signIn';

import { smileO } from 'react-icons-kit/fa/smileO';
import { userMd } from 'react-icons-kit/fa/userMd';
import { medkit } from 'react-icons-kit/fa/medkit';

import { ambulance } from 'react-icons-kit/fa/ambulance';
import { heartbeat } from 'react-icons-kit/fa/heartbeat';
import { server } from 'react-icons-kit/fa/server';
import { fighterJet } from 'react-icons-kit/fa/fighterJet';

import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
// import Image from '../../../reusecore/src/elements/Image';
import Container from '../../../common/src/components/UI/Container';
import FeatureBlock from '../../../common/src/components/FeatureBlock';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { FeatureWrapper } from './keyFeatures.style';

const KeyFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        keyFeatures {
          slogan
          title
          features {
            id
            picture
            color
            leftIcon
            rightIcon
            bottomIcon
            title
            description
          }
        }
      }
    }
  `);
  const { slogan, title, features } = data.appClassicJson.keyFeatures;
  const getIcon = (iconName) => {
    if (iconName != null) {
      switch (iconName.toLowerCase()) {
        case 'trophy':
          return trophy;
        case 'cloud':
          return cloud;
        case 'lock':
          return lock;
        case 'users':
          return users;
        case 'thumbsup':
          return thumbsUp;
        case 'signin':
          return signIn;
        case 'smileo':
          return smileO;
        case 'usermd':
          return userMd;
        case 'medkit':
          return medkit;
        case 'ambulance':
          return ambulance;
        case 'heartbeat':
          return heartbeat
        case 'server':
          return server;
        case 'fighterjet':
          return fighterJet;
        default:
          return lock;
      }
    } else {
      return lock;
    }
  };

  return (
    <SectionWrapper id="remedi">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
          </Fade>
        </SectionHeader>
        <FeatureWrapper>
          {features.map(item => (

            <Fade up delay={100 * item.id} key={`key-feature--key${item.id}`}>
              <FeatureBlock
                style={{ '--color': `${item.color}` }}
                icon={
                  <Fragment>
                    <Icon className="leftIcon" icon={getIcon(item.leftIcon)} />
                    <Icon className="rightIcon" icon={getIcon(item.rightIcon)} />
                    <Icon icon={getIcon(item.picture)} className="shown" size={80} />
                    <Icon className="bottomIcon" icon={getIcon(item.bottomIcon)} />
                  </Fragment>
                }
                title={<Heading as="h3" content={item.title} />}
                description={<Text content={item.description} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default KeyFeatures;
